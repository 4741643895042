import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layouts";


const IndexPage = () => {

  return (
    <Layout>
      <section className="w-100 pos-r height-100-vh">
        <div className="pos-a h-100 w-100">
          <StaticImage
            src="../images/hero-bg.jpg"
            alt="hero image"
            placeholder="blurred"
            layout="fullWidth"
            className="h-100 w-100"
          />
        </div>
        <div className="pos-a bg-gradient w-100 h-100">
        </div>
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c height-100-vh pos-r z-depth-3">
          <div className="wrapper-y will-grow h-100  main-hero j-c-c">
            <div className="wrapper-yt will-grow">
              <div className="maxwidth-mt wrapper will-grow ">
                <h1 className="co-white very-largetext">
                  Send and manage money in multiple currencies.
                </h1>
                <p className="maxwidth-sm smalltext co-white my-2 py-1">
                Manage your money, exchange international currencies, bills and savings from your phone. ROAVA connects you to countries, new opportunities and experiences in Africa and across the world.
                </p>
                <div className="mb-3">
                  <p className="co-white">Want to stay close to what we’re building?</p>
                  <button className="btn bg-blue co-white px-3 py-1h rad-10 register" type="button">
                    Get early access?
                  </button>
                </div>
                <div className="d-flx al-i-c">
                  <span className="picotext co-white">COMING SOON ON</span>
                  <div className="suffix is-even-wider">
                    <span className="prefix is-wider">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width={31} height={31} rx="9.5" fill="white" stroke="#EFEFEF" />
                        <g clipPath="url(#clip0)">
                          <path d="M18.2253 10.597C18.7473 9.92399 19.1423 8.97199 18.9993 8C18.1463 8.059 17.1493 8.605 16.5683 9.31599C16.0383 9.96099 15.6023 10.919 15.7723 11.85C16.7043 11.879 17.6673 11.32 18.2253 10.597Z" fill="#272727" />
                          <path d="M22.3703 13.3681C21.5513 12.3411 20.4003 11.7451 19.3133 11.7451C17.8783 11.7451 17.2713 12.4321 16.2743 12.4321C15.2463 12.4321 14.4653 11.7471 13.2243 11.7471C12.0054 11.7471 10.7074 12.4921 9.88437 13.7661C8.72738 15.5601 8.92537 18.9331 10.8004 21.8061C11.4714 22.8341 12.3674 23.99 13.5393 24C14.5823 24.01 14.8763 23.3311 16.2893 23.3241C17.7023 23.3161 17.9703 24.009 19.0113 23.998C20.1843 23.989 21.1293 22.7081 21.8003 21.6801C22.2813 20.9431 22.4603 20.5721 22.8333 19.7401C20.1203 18.7071 19.6853 14.8491 22.3703 13.3681Z" fill="#272727" />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width={16} height={16} fill="white" transform="translate(8 8)" />
                          </clipPath>
                        </defs>
                      </svg>
                      </span>
                    <span className="prefix is-wider">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width={31} height={31} rx="9.5" fill="white" stroke="#EFEFEF" />
                        <path d="M22.3879 14.8003L19.6782 13.2354L15.9657 16.0092L19.6781 18.7831L22.397 17.2128C22.821 16.9552 23.074 16.5052 23.074 16.0093C23.074 15.5134 22.8209 15.0634 22.3879 14.8003Z" fill="#FF9100" />
                        <path d="M16.9043 16.0097C16.9002 16.0149 13.6218 20.23 13.6192 20.2334L9.34852 23.5967C9.08568 23.3338 8.92615 22.9772 8.92615 22.583V9.41307C8.92615 9.01889 9.08568 8.66219 9.34852 8.39941L13.6192 11.786C13.6218 11.7894 16.9002 16.0044 16.9043 16.0097Z" fill="#25D9F8" />
                        <path d="M19.6825 13.2311L16.9043 16.0094L9.34851 8.39917C9.44234 8.31467 9.53623 8.24897 9.63948 8.18326C10.09 7.92987 10.625 7.93923 11.0662 8.21145L15.9657 11.0911L19.6825 13.2311Z" fill="#7FE881" />
                        <path d="M19.6825 18.7875L15.9657 20.9276L11.0568 23.7934C10.8315 23.9248 10.5875 23.9999 10.334 23.9999C10.0994 23.9999 9.86474 23.9342 9.63948 23.8122C9.53623 23.7465 9.44234 23.6808 9.34851 23.5963L16.9043 16.0093L19.6825 18.7875Z" fill="#FF637B" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="maxwidth-sl mx-auto" id="features">
        <div className="wrapper">
          <h2 className="wrapper-yt">Features</h2>
          <span>
            <svg width={327} height={14} viewBox="0 0 327 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x={204} width={123} height={14} rx={7} fill="#FF34BA" />
              <rect x={102} width={123} height={14} rx={7} fill="#842DB5" />
              <rect width={123} height={14} rx={7} fill="#3579F5" />
            </svg>
          </span>
        </div>
      </section>


      <section className="w-100">
        <div className="maxwidth-sl mx-auto">
          <div className="wrapper-x will-grow">
            <div className="clearfix al-i-c wrapper-y will-grow">
              <div className="left-50">
                <div className="maxwidth-xs mx-auto wrapper-x pos-r">
                  <StaticImage
                    src="../images/iphone.png"
                    alt="roava payments"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="phone-mockup"
                  />
                </div>
              </div>
              <div className="right-50 d-flx flex-dir-col j-c-c wrapper-y will-grow">
                <div className="icon-area">
                  <span className="prefix is-even-wider">
                    <StaticImage
                      src="../images/payments.png"
                      alt="roava payments"
                      placeholder="blurred"
                      layout="fullWidth"
                      className="small-avatar"
                    />
                  </span>
                </div>
                <div>
                  <h3 className="largetext mt-2 mb-1">
                  Seamless International Transfers
                  </h3>
                  <p className="microtext maxwidth-sm">
                  Send and receive payments  in multiple currencies to support family, manage international commitments or support your lifestyle without borders.
                  </p>
                </div>
                <p className="my-2 microtext maxwidth-sm">
                ROAVA will support international currency exchange in up to 25 currencies and specifically also across Africa. We’re starting with the most populous country in the Sub-sahara continent but watch this space for more African currencies coming soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100 pos-r height-80-vh hide-flow-x hide-flow-y bg-branddark">
        <div className="pos-a show-mediumup background-gray"></div>
        <div className="maxwidth-sl mx-auto pos-r z-depth-3">
          <div className="wrapper will-grow">
            <div className="grid2 al-i-c wrapper-y">
              <div className="order-rv">
                <div className="maxwidth-xs mx-auto wrapper-yt will-grow wrapper-x pos-r">
                  <StaticImage
                    src="../images/iphone-accounts.png"
                    alt="roavaa"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="phone-mockup"
                  />
                </div>
              </div>
              <div className="d-flx flex-dir-col j-c-c wrapper-y will-grow">
                <div className="icon-area">
                  <span className="prefix is-even-wider">
                    <StaticImage
                      src="../images/currency.png"
                      alt="hero image"
                      placeholder="blurred"
                      layout="fullWidth"
                      className="small-avatar"
                    />
                  </span>
                </div>
                <h3 className="co-white largetext mt-2">
                  Without Financial Borders
                </h3>
                <p className="co-white smalltext maxwidth-sm">
                Manage your money in multiple currencies from your phone with a simple intuitive interface and without the restriction of common financial borders. You’ll also get real-time notifications when you spend or get paid.

                </p>
                <p className=" co-white my-1 smalltext maxwidth-sm">
                ROAVA offers you a regulated UK bank account covered by the Financial Services Compensation Scheme (FSCS).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100">
        <div className="maxwidth-sl mx-auto">
        <div className="wrapper will-grow">
          <div className="grid2 al-i-c wrapper-y">
            <div className="">
              <div className="maxwidth-xs mx-auto wrapper-x pos-r">
                <StaticImage
                  src="../images/iphone-savings.png"
                  alt="hero image"
                  placeholder="blurred"
                  layout="fullWidth"
                  className="phone-mockup"
                />
              </div>
            </div>
            <div className="d-flx flex-dir-col j-c-c wrapper-y will-grow">
              <div className="icon-area">
                <span className="prefix is-even-wider">
                  <StaticImage
                    src="../images/savings.png"
                    alt="hero image"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="small-avatar"
                  />
                </span>
              </div>
              <h3 className="largetext mt-2">
              High Interest Savings
              </h3>
              <p>Our savings vaults fixed term deposit accounts offering high interest with up to #.##% AER and starting with 1,000 GBP</p>
              <div className="my-2">
                <ul className="grid-2-icons none">
                  <li>
                    <span>
                      <StaticImage
                        src="../images/flexibility.png"
                        alt=""
                        placeholder="blurred"
                        layout="fullWidth"
                        className="icon"
                      />
                    </span>
                    <h3>Flexibility</h3>
                    <p className="smalltext mt-1">Notice periods of #, # and ## months</p></li>
                  <li>
                    <span>
                      <StaticImage
                        src="../images/instant.png"
                        alt=""
                        placeholder="blurred"
                        layout="fullWidth"
                        className="icon"
                      />
                    </span>
                    <h3>Instant Balances</h3>
                    <p className="smalltext mt-1">Visualise balances and interest earned on your phone</p>
                  </li>

                  <li>
                    <span>
                    <StaticImage
                        src="../images/signup.png"
                        alt="fscs"
                        placeholder="blurred"
                        layout="fullWidth"
                        className="icon"
                      />
                    </span>
                    <h3>Easy Sign-up</h3>
                    <p className="smalltext mt-1">Digital registration. Apply on your phone in minutes</p>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

      <section className="w-100 bg-lightgray pos-r hide-flow-x">
        <div className="pos-a show-mediumup background-tone"></div>
        <div className="maxwidth-sl mx-auto">
          <div className="wrapper will-grow">
            <div className="clearfix al-i-c wrapper-y">
              <div className="right-50">
                <div className="maxwidth-xs mx-auto wrapper-x pos-r">
                  <StaticImage
                    src="../images/iphone-card.png"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                    className="phone-mockup"
                  />
                </div>
                <div className="pos-a"></div>
              </div>
              <div className="left-50 d-flx flex-dir-col j-c-c wrapper-y will-grow">
                <div className="icon-area">
                  <span className="prefix is-even-wider">
                    <StaticImage
                      src="../images/card.png"
                      alt="hero image"
                      placeholder="blurred"
                      layout="fullWidth"
                      className="small-avatar"
                    />
                  </span>
                </div>
                <div>
                  <p className="my-2 co-magenta">COMING SOON</p>
                  <h3 className="almost-largetext mt-2">
                    Roava Card Payments to Anywhere from One Source
                  </h3>
                </div>
                <p className="my-2 smalltext maxwidth-sm">
                  We will be launching a multi-currency card which you can manage from your phone and use to spend abroad with competitive exchange rates in multiple currencies.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100 bg-rich-black pos-r" id="about">
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c pos-r z-depth-3">
          <div className="wrapper-y will-grow h-100 main-hero j-c-c">
            <div className="">
              <div className="maxwidth-mt wrapper will-grow ">
                <h1 className="co-white very-largetext">
                It is our mission to remove barriers for you to operate with multiple currencies.
                </h1>
                <p className="maxwidth-sm smalltext co-white my-2 py-1">
                You will be able to make hassle-free international payments and request for funds in multiple currencies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100">
        <div className="main-wrap mx-auto">
        <div className="wrapper-y">
          <div className="wrapper-y ">
            <div className="d-flx flex-dir-col j-c-c tx-c wrapper will-grow">
              <h3 className="largetext mt-0">
                Sneak peak?
              </h3>
              <p className="my-2 smalltext maxwidth-tb mx-auto">
              Want to stay close to what we’re building? 
              </p>
              <div className="d-flx j-c-c">
                <button className="btn bg-blue co-white px-3 py-1h rad-10 register" type="button">
                Get early access?
                </button>
              </div>
              <div className="wrapper-yt will-grow">
                <p>Are you interested in what we’re building? <a href="mailto:connect@roava.app" className="co-magenta">Join us</a></p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
